import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import styles from "./aboutME.module.css"
import MyResume from "../../static/PatrickOHern_Resume.pdf"



export default function Home() {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10%",
        }}
      >
        {/* <Header headerText="About Me" /> */}
        <img
          src="https://i.imgur.com/ckh27x4.jpg"
          title="source: imgur.com"
          alt="Headshot"
          className={styles.picture}
        />
        <div className={styles.story}>
          <h4 className={styles.p}>
            From bar manager to full-stack software engineer, I always strive to
            alleviate the problem and come to the solution in a level-headed
            manner, working quickly to solve problems in high pressure
            situations. I strive for autonomy and work life balance. I look
            forward to using my past experience to help solve the problems of
            today. I'd love to connect!
          </h4>
        </div>

        <p className={styles.center}>My Resume</p>
        <a
          className={styles.index}
          href={MyResume}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.pinimg.com/originals/7f/d2/e4/7fd2e46b2da9819e667fb75caf475cf7.png"
            className={styles.resume}
            alt="Resume"
          ></img>
        </a>
        <p className={styles.center}>Skills</p>
        <div className={styles.index}>
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png"
            alt="ReactImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/html/html.png"
            alt="HTMLImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/javascript/javascript.png"
            alt="JSImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/css/css.png"
            alt="CSSImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/bootstrap/bootstrap.png"
            alt="BootstrapImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/nodejs/nodejs.png"
            alt="NodeImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/python/python.png"
            alt="PythonImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/django/django.png"
            alt="DjangoImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/mongodb/mongodb.png"
            alt="MongoDBImg"
          />
          <img
            className={styles.img}
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/sql/sql.png"
            alt="SQLImg"
          />
        </div>
      </div>
    </Layout>
  )
}
